/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #3a66b3;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #2a4677;
	--ion-color-primary-tint: #6e9be9;

	/** secondary **/
	--ion-color-secondary: #6698e8;
	--ion-color-secondary-rgb: 12, 209, 232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;

	/** tertiary **/
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112, 68, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** extra **/
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 1px) {
	.stImages {
		max-height: 200px !important;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.innerDropZone {
		width: 300px;
		height: 300px;
	}
	.stImages {
		max-height: 300px !important;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	/* .container {
		height: 300px;
	} */
	.stButton {
		margin-top: 16px;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.innerDropZone {
		width: 400px;
		height: 400px;
	}
	.stImages {
		max-height: 400px !important;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.dropShadow {
	box-shadow: 0px 14px 23px -1px rgba(0, 0, 0, 0.4) !important;
}

.innerShadow {
	box-shadow: inset 1px 5px 23px -1px rgba(0, 0, 0, 0.1) !important;
}

.container {
	display: flex;
	justify-content: center;
}

.center {
	text-align: center !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.displayImage {
	max-height: 400px;
}
.displayImage2 {
	max-height: 400px;
	padding: 0;
	height: auto;
	display: flex;
}
.displayImage3 {
	padding: 0;
	height: auto;
	display: flex;
}

.thumbNails {
	max-height: 50px;
	margin: 20px 0;
}
.thumbnailCardContent {
	height: 50px;
	width: 50px;
}
.thumbnail {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.stPicker {
	--background-checked: #6a6b71;
	--handle-background-checked: #fff;
}

#Slow {
	color: lightgray;
}

#Fast {
	color: white;
}

.fancyButton {
	--background: rgba(0, 0, 0, 0.1);
	--color: rgb(255, 255, 255);
	--border-color: white;
}

.stCardHeader {
	display: flex;
	justify-content: space-between;
	padding: 8px;
}
.stCardHeaderChildren {
	align-self: center;
	margin: 0px;
}

.stCardHeaderText {
	margin-bottom: 2px;
	margin-left: 8px;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

#parent {
	display: flex;
}

#labelTest {
	display: block;
}

ion-card {
	max-height: 100%;
	object-fit: contain !important;
}

ion-card-content {
	height: 100%;
	object-fit: contain !important;
}

a {
	text-decoration: none;
}

.nukeButton {
	--background: rgb(238, 220, 62);
	--ripple-color: rgb(255, 254, 247);
	--color: black !important;
}
.flowerButton {
	--background: rgb(72, 219, 91);
	--ripple-color: rgb(0, 0, 0);
	--color: black !important;
}

.loading {
	padding-left: 15px;
}

.stSpacer {
	max-width: 200px;
}

.dropZone {
	display: inherit;
}

.innerDropZone {
	display: inherit;
}

.innerDropZone:hover {
	border-color: #b8c5cf !important;
	background-color: #d3e0e9 !important;
}

.uploadIcon {
	font-size: calc(32px + 5vw);
}
.uploadInner {
	pointer-events: none;
}

.stImages {
	border-radius: 10px;
	object-fit: contain;
	display: flex;
	max-height: 300px;
	max-width: 300px;
	min-height: 100px;
	min-width: 100px;
}
.warning {
	color: rgb(255, 225, 57);
	font-size: small;
	text-align: center;
}
.loader {
	color: #ffffff;
	font-size: 0.2em;
	margin: 100px auto;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: load4 1.3s infinite linear;
	animation: load4 1.3s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
@-webkit-keyframes load4 {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
			0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
			0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
			0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
			0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
			0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
			0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
			2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
			-2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
			0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
}
@keyframes load4 {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
			0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
			0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
			0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
			0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
			0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
			0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
			2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
			-2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
			0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
}
